import React, { FC } from 'react';
import { graphql } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import 'styles/main.scss';
import './FaqPage.scss';

import FaqAccordion from 'components/FaqAccordion';
import Layout from 'components/Layout';
import ProductInfo from 'components/ProductInfo';
import { IFaqPage } from './models';

const FaqPage: FC<IFaqPage> = ({
  data: {
    umbracoFaq,
    siteSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const {
    subMenu,
    description,
    title,
    image,
    imageAlt,
    faqSections,
    seoMetaDescription,
    seoMetaKeywords,
    seoMetaTitle,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
    submenuAriaLabel,
    link,
  } = umbracoFaq;

  return (
    <Layout
      subMenu={{
        links: subMenu,
        ariaLabel: submenuAriaLabel,
      }}
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      languageSelector={languageSelector}
      url={link}
    >
      <div className="dt-faq-page">
        <Container fluid>
          <Row>
            <Col sm={12} md={6}>
              <ProductInfo
                articleImage={image}
                title={title}
                text={description}
                type="article"
                alt={imageAlt}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 9, offset: 2 }}>
              {faqSections?.map((section, index) => (
                <div key={section.title} id={subMenu[index].linkToSection}>
                  <FaqAccordion {...section} />
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query FaqPageQuery($lang: String) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
    }
    umbracoFaq(lang: { eq: $lang }) {
      link
      image {
        fallbackUrl
        fluid {
          srcSet
          base64
        }
      }
      description
      title
      imageAlt
      faqSections {
        title
        faqItems {
          properties {
            answer
            question
          }
        }
      }
      subMenu {
        linkToSection
        name
      }
      submenuAriaLabel
      seoMetaDescription
      seoMetaKeywords
      seoMetaTitle
      seoCanonicalUrl
      seoImage
      seoExternalHreflangs {
        key
        value
      }
    }
  }
`;

export default FaqPage;
